"use client";

import { Menubar } from "primereact/menubar";
import Logo from "@/components/Public/Logo";
import Link from "next/link";
import { Button } from "primereact/button";
import { useEffect, useMemo, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import AppToast from "./AppToast";
import { logOut } from "@/api/user";
import {
  PICKS_SITES,
  PUBLIC_PICKS_SITES,
  PUBLIC_SPORTS,
  SPORTS,
} from "@/constraints";
import * as React from "react";
import DatePicker from "./DatePicker";
import BettingLogo from "@/components/Public/Picks/PicksWidget/PicksDataView/BettingLogo";
import { getBettingSites, LogoColors } from "@/utils/common";
import User from "@/types/user";

interface NavBarProps {
  user: User;
}

const NavBar = ({ user }: NavBarProps) => {
  const router = useRouter();
  let items = [];
  const pathname = usePathname();
  const isStaff: boolean = user && user.is_staff;
  const bettingSites: string[] = getBettingSites(user?.show_ru);
  const sportChoices: string[] = isStaff ? SPORTS : PUBLIC_SPORTS;
  const [sites, setSites] = useState<string[]>([]);

  useEffect(() => {
    if (!isStaff) {
      setSites(PUBLIC_PICKS_SITES);
    } else {
      setSites(Object.keys(PICKS_SITES));
    }
  }, [isStaff]);

  const loginButton = useMemo(() => {
    if (user) {
      return (
        <div className="flex gap-2">
          <Button
            className="md:mr-3 lg:mr-3 xl:mr-3"
            label="Account"
            onClick={async () => {
              router.push("/account");
            }}
            rounded={true}
          />
          <Button
            label="Quit"
            outlined
            onClick={async () => {
              await logOut();
              router.push("/login");
            }}
            rounded={true}
          />
        </div>
      );
    } else {
      return (
        <>
          <Button
            label="Sign In"
            outlined
            // rounded
            onClick={() => {
              router.push("/login");
            }}
          />
        </>
      );
    }
  }, [router, user]);
  const propSitesChoices = useMemo(() => {
    const baseChoices = sites.map((label) => ({
      label: label,
      items: ["ALL", ...sportChoices].map((sport) => ({
        label: sport,
        url: `/picks/${label.toLowerCase()}/${sport.toLowerCase()}`,
      })),
    }));
    if (sites.length < 5) {
      return baseChoices;
    } else {
      return [
        {
          label: "Picks",
          items: baseChoices,
        },
      ];
    }
  }, [sites, sportChoices]);
  if (!pathname.includes("/office")) {
    items = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Blog",
        url: "/blog",
      },
      ...propSitesChoices,
      {
        label: "Underdog Rivals",
        url: `/rivals/underdog/`,
      },
      {
        label: "Bookmakers",
        items: ["ALL", ...sportChoices].map((sport) => ({
          label: sport,
          items: bettingSites.map((site) => ({
            label: (
              <div
                style={{ backgroundColor: LogoColors[site] }}
                className="navbar-bookmaker"
              >
                <BettingLogo site={site} width={180} height={25} />
              </div>
            ),
            url: `/bookmakers/picks/${sport.toLowerCase()}/${site.toLowerCase()}`,
            className: "navbar-bookmaker",
          })),
        })),
      },
      {
        label: "Calculator",
        url: `/calculator`,
      },
    ];
  } else {
    const sportItems = sportChoices.map((sport) => ({
      label: sport,
      url: `/office/lines/${sport.toLowerCase()}`,
    }));
    items = [...items, { label: "Sport", items: sportItems }];
    items.push({ template: () => <DatePicker /> });
    items.push({
      label: "Articles",
      items: [
        { label: "List", url: "/office/articles" },
        { label: "Add New", url: "/office/articles/add-article" },
      ],
    });
    items.push({
      label: "Updates Tracker",
      url: "/office/updates-tracker",
    });
  }
  items.push({
    template: (
      <div className="flex flex-column gap-2 align-items-center">
        {loginButton}
      </div>
    ),
    className: "lg:hidden xl:hidden",
  });

  const start = (
    <div className="justify-content-center align-content-center">
      <Link href="/">
        <Logo />
      </Link>
    </div>
  );

  const end = <div className="hidden lg:block mr-5">{loginButton}</div>;

  return (
    <div className="navbar-container">
      <AppToast />
      <Menubar
        model={items}
        start={start}
        style={{ width: "100%" }}
        className="menu-bar-styled"
        end={end}
      />
    </div>
  );
};

export default NavBar;
