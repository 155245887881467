import Image from "next/image";
import React from "react";

interface BettingLogoProps {
  site: string;
  height: number;
  width: number;
}

const BettingLogo = ({ site, height = 20, width = 100 }: BettingLogoProps) => {
  return (
    <Image
      src={`/images/site-logos/${site}.svg`}
      alt="logo"
      height={height}
      width={width}
    />
  );
};

export default BettingLogo;
