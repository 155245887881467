export const SPORTS = [
  "MLB",
  "NFL",
  "NHL",
  "NBA",
  "CS",
  "LOL",
  "CFB",
  "CBB",
  "WNBA",
  "VAL",
];

export const PUBLIC_SPORTS = ["NBA", "NHL", "NFL", "MLB", "WNBA"];

export const PICKS_SITES = {
  Prizepicks: "line_score",
  UnderDog: "ud_line_score",
  Drafters: "drafters_line_score",
  Superdraft: "sd_line_score",
  ThriveFantasy: "tf_line_score",
  ParlayPlay: "parlayplay_line_score",
  IKB: "ikb_line_score",
  OwnersBox: "ownersbox_line_score",
  Sleeper: "sleeper_line_score",
  Picks_6: "picks_6_line_score",
};

export const PICKS_SITES_WITH_COEFFICIENTS = ["sleeper", "parlayplay"];

export const PUBLIC_PICKS_SITES = [
  "Prizepicks",
  "Underdog",
  "Parlayplay",
  "Drafters",
  "Superdraft",
  "OwnersBox",
  "Sleeper",
];

export const PICKS_SITES_WITH_KEYS = {
  prizepicks: "line_score",
  underdog: "ud_line_score",
  drafters: "drafters_line_score",
  superdraft: "sd_line_score",
  thrivefantasy: "tf_line_score",
  parlayplay: "parlayplay_line_score",
  ikb: "ikb_line_score",
  ownersbox: "ownersbox_line_score",
  sleeper: "sleeper_line_score",
  picks_6: "picks_6_line_score",
  draftkings: "draftkings_points",
  pinnacle: "pinnacle_points",
  // unibets: "unibets_points",
  // partycasino: "partycasino_points",
  caesar: "caesar_points",
  stake: "stake_points",
  // sportsinteraction: "sportsinteraction_points",
  williamhill: "williamhill_points",
  // xbet: "xbet_points",
  fd: "fd_points",
  tonybet: "tonybet_points",
  bovada: "bovada_points",
  betrivers: "betrivers_points",
  betonlineag: "betonlineag_points",
  barstool: "barstool_points",
  betmgm: "betmgm_points",
  pointsbetus: "pointsbetus_points",
  melbet_ru: "melbet_ru_points",
  espnbet: "espnbet_points",
  hardrockbet: "hardrockbet_points",
};

export const BETTING_SITES = [
  "draftkings",
  "pinnacle",
  // "unibets",
  // "partycasino",
  "caesar",
  "stake",
  // "sportsinteraction",
  "williamhill",
  // "xbet",
  "fd",
  "tonybet",
  "bovada",
  "betrivers",
  "betonlineag",
  "barstool",
  "betmgm",
  "pointsbetus",
  "espnbet",
  "hardrockbet",
];

export const RU_BETTING_SITES = ["melbet_ru"];

export const allAggregatedColumns = [
  "crunch_points",
  "crunch_alternate_points",
  "weighted_points",
  "etr_points",
  "awesemo_points",
  "rotogrinders_points",
  "rotogrinders_bat_points",
  "betting_props_points",
  "rotowire_points",
  "linestar_points",
  "sabersim_points",
  "dfn_points",
  "shipitnation_points",
  "ftn_points",
  "pinnacle_probability_converted",
  "draftkings_probability_converted",
  "unibets_probability_converted",
  // "partycasino_probability_converted",
  "caesar_probability_converted",
  "fd_probability_converted",
  "tonybet_probability_converted",
  "bovada_probability_converted",
  "betrivers_probability_converted",
  "betonlineag_probability_converted",
  "barstool_probability_converted",
  "betmgm_probability_converted",
  "pointsbetus_probability_converted",
  "stake_probability_converted",
  // "sportsinteraction_probability_converted",
  "williamhill_probability_converted",
  // "xbet_probability_converted",
  "espnbet_probability_converted",
  "hardrockbet_probability_converted",
];

export const CREDENTIALS = {
  NEXT_PUBLIC_PAYPAL_WEEKLY_PLAN_ID:
    process.env.NEXT_PUBLIC_PAYPAL_WEEKLY_PLAN_ID,
  NEXT_PUBLIC_PAYPAL_MONTHLY_PLAN_ID:
    process.env.NEXT_PUBLIC_PAYPAL_MONTHLY_PLAN_ID,
  NEXT_PUBLIC_PAYPAL_YEARLY_PLAN_ID:
    process.env.NEXT_PUBLIC_PAYPAL_YEARLY_PLAN_ID,
  NEXT_PUBLIC_PAYPAL_CLIENT_ID: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
};
