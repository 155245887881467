import { BETTING_SITES, RU_BETTING_SITES } from "@/constraints";

export function normalizeText(string: string) {
  return string
    .split(/[ _-]/)
    .map((v) => v.charAt(0).toUpperCase() + v.substring(1).toLowerCase())
    .join(" ");
}

export const percentageToDecimalOdds = (percentage: number): number => {
  if (percentage < 1) {
    percentage *= 100;
  }
  return 1 / (percentage / 100);
};

export const percentageToAmericanOdds = (percentage: number): number => {
  if (percentage < 1) {
    percentage *= 100;
  }
  const decimalOdds = percentageToDecimalOdds(percentage);
  let value: number;
  if (decimalOdds < 2) {
    value = -100 / (decimalOdds - 1);
  } else {
    value = (decimalOdds - 1) * 100;
  }
  return Math.round(value);
};

export const round = (num: number): number => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export function percentageToFractionalOdds(percentage: number): string {
  // Ensure the input percentage is within the valid range (0 to 100)
  if (percentage < 0 || percentage > 100) {
    throw new Error("Percentage must be between 0 and 100.");
  }

  // Convert the percentage to a decimal odds value
  const decimalOdds = 100 / percentage;

  // Convert the decimal odds to fractional odds
  const numerator = decimalOdds - 1;
  const denominator = 1;

  return `${numerator.toFixed(0)}/${denominator.toFixed(0)}`;
}

export const LogoColors = {
  draftkings: "var(--yellow-200)",
  pinnacle: "var(--blue-700)",
  unibets: "var(--green-200)",
  partycasino: "var(--cyan-100)",
  caesar: "var(--surface-800)",
  stake: "white",
  sportsinteraction: "var(--text-color-secondary)",
  williamhill: "var(--cyan-800)",
  xbet: "var(--cyan-50)",
  fd: "var(--cyan-50)",
  prizepicks: "var(--text-color-secondary)",
  underdog: "var(--teal-300)",
  drafters: "var(--yellow-300)",
  superdraft: "var(--cyan-900)",
  thrivefantasy: "var(--yellow-100)",
  parlayplay: "var(--yellow-500)",
  ikb: "white",
  tonybet: "var(--blue-600)",
  bovada: "var(--red-100)",
  betrivers: "var(--orange-100)",
  betonlineag: "var(--purple-700)",
  barstool: "var(--pink-100)",
  betmgm: "white",
  pointsbetus: "var(--lime-500)",
  ownersbox: "var(--cyan-400)",
  sleeper: "var(--blue-200)",
  espnbet: "var(--yellow-100)",
  hardrockbet: "var(--teel-800)",
  picks_6: "#000000",
};

export function formatPrettierDate(isoDateString: string): string {
  const dateObj = new Date(isoDateString);

  return dateObj.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
}

function convertToNumberIfPossible(value: any): any {
  if (typeof value === "string") {
    let convertedNumber = parseFloat(value);
    if (!isNaN(convertedNumber)) {
      return convertedNumber;
    }
  }
  return value;
}

export function convertObjectValues(
  obj: Record<string, any>,
): Record<string, any> {
  Object.keys(obj).forEach((key) => {
    if (key !== "id") {
      obj[key] = convertToNumberIfPossible(obj[key]);
    }
  });
  return obj;
}

export function roundToTwoDecimals(num: number): number {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function decimalToAmerican(decimalOdds: number): string {
  if (decimalOdds === 1) {
    return "Equal";
  } else if (decimalOdds >= 2.0) {
    // For decimal odds greater than or equal to 2.00, positive American odds are calculated
    return "+" + Math.round((decimalOdds - 1) * 100);
  } else {
    // For decimal odds less than 2.00, negative American odds are calculated
    return ("-" + Math.round((1 / (decimalOdds - 1)) * 100)).replace("--", "-");
  }
}

export function getBettingSites(show_ru: boolean): string[] {
  if (show_ru) {
    return [...BETTING_SITES, ...RU_BETTING_SITES];
  } else {
    return BETTING_SITES;
  }
}
