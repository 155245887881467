"use client";

import { Calendar } from "primereact/calendar";
import * as React from "react";
import { useParams, useRouter, useSearchParams } from "next/navigation";

const DatePicker = () => {
  const router = useRouter();
  const { sport } = useParams() as Record<string, string>;
  const searchParams = useSearchParams();
  let dateString = searchParams.get("date");

  if (!dateString) {
    dateString = new Date().toISOString();
  }
  const date = new Date(dateString);
  date.setMinutes(0);
  date.setHours(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  const handleDateChange = (value: Date) => {
    const newDate = value.toISOString();
    router.push(
      `/office/lines/${sport}/?${new URLSearchParams({ date: newDate })}`,
    );
  };

  return (
    <Calendar
      id="calendar"
      className="date-picker"
      value={date}
      onChange={(e) => handleDateChange(e.value as Date)}
    />
  );
};

export default DatePicker;
