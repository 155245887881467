"use client";

import React, { createContext, ReactElement, useState } from "react";

interface MessageContextInterface {
  successMessages: (string | object)[];
  setSuccessMessages: React.Dispatch<React.SetStateAction<string[]>>;
  errorMessages: (string | object)[];
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>;
  addSuccessMessage: (message: string) => void;
  addErrorMessage: (message: string) => void;
}

export const MessageContext = createContext<
  MessageContextInterface | undefined
>(undefined);

interface MessageProviderProps {
  children: ReactElement | ReactElement[];
}

export default function MessageProvider({ children }: MessageProviderProps) {
  const [successMessages, setSuccessMessages] = useState<(string | object)[]>(
    [],
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  function addSuccessMessage(message: string) {
    setSuccessMessages((prevState) => [...prevState, message]);
  }
  function addErrorMessage(message: string) {
    setErrorMessages((prevState) => [...prevState, message]);
  }
  return (
    <MessageContext.Provider
      value={{
        successMessages,
        setSuccessMessages,
        errorMessages,
        setErrorMessages,
        addSuccessMessage,
        addErrorMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
}
