"use client";

import React, { useContext, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { MessageContext } from "@/contexts/MessageProvider";

const AppToast = () => {
  const toast = useRef(null);

  const {
    successMessages,
    errorMessages,
    setSuccessMessages,
    setErrorMessages,
  } = useContext(MessageContext);

  useEffect(() => {
    if (successMessages.length) {
      successMessages.forEach((message) => {
        if (typeof message === "object") {
          Object.entries(message).forEach(([key, values]) => {
            values.forEach((value: string) => {
              const messageText = `${key}: ${value}`;
              toast.current.show({
                severity: "success",
                detail: messageText,
                life: 5000,
              });
            });
          });
        } else {
          toast.current.show({
            severity: "success",
            detail: message,
            life: 5000,
          });
        }
      });
      setSuccessMessages([]);
    }
  }, [successMessages, setSuccessMessages]);

  useEffect(() => {
    if (errorMessages.length) {
      errorMessages.forEach((message) => {
        if (typeof message === "object") {
          Object.entries(message).forEach(([key, values]) => {
            values.forEach((value: string) => {
              const messageText = `${key}: ${value}`;
              toast.current.show({
                severity: "error",
                detail: messageText,
                life: 5000,
              });
            });
          });
        } else {
          toast.current.show({
            severity: "error",
            detail: message,
            life: 5000,
          });
        }
        setErrorMessages([]);
      });
    }
  }, [errorMessages, setErrorMessages]);

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
    </div>
  );
};

export default AppToast;
